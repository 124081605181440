import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  AuthorizedPage,
  CustomApp,
  TenantLayout,
  useThemeContext,
} from "@maxtropy/components";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { useEffect, useMemo } from "react";
const getSubUrl = (subName: string, devUrl: string): string => {
  if (import.meta.env.DEV) return devUrl;
  return window.location.origin + "/" + subName;
};

const appList = [
  {
    name: "ms-ds",
  },
  {
    name: "ms-dc",
    fullScreenRouteList: ["/scada/:id/edit", "/scada/:id"],
  },
  {
    name: "ms-cc",
    fullScreenRouteList: ["/qingflow/error"],
  },
  {
    name: "ms-ids",
  },
  {
    name: "ms-wo",
  },
  {
    name: "ms-ji",
  },
  {
    name: "ms-zm",
  },
  {
    name: "ms-ai",
  },
  {
    name: "ms-pa",
  },
  {
    name: "ms-rt",
  },
  {
    name: "ms-ba",
  },
  {
    name: "ms-ec",
  },
  {
    name: "ms-en",
  },
  {
    name: "ms-gp",
  },
  {
    name: "ms-pr",
  },
  {
    name: "ms-ca",
  },
];

const noLayoutAppList = [
  {
    name: "ms-ac",
  },
];

// const isvAppList = [];

const getMicroAppElement = (name: string, fullScreen?: boolean) => {
  return (
    <micro-app
      iframe
      name={name}
      url={getSubUrl(name, "http://localhost:9100")}
      baseroute="/infra"
      style={
        fullScreen
          ? {
              height: "100vh",
              width: "100vw",
            }
          : {
              height: "100%",
              width: "100%",
            }
      }
    />
  );
};

function App() {
  const theme = useThemeContext();

  return (
    <ConfigProvider
      prefixCls="infra"
      locale={zhCN}
      theme={theme}
      space={{ size: 3 }}
    >
      <CustomApp style={{ height: "100vh" }}>
        <BrowserRouter basename="/infra">
          <Routes>
            {/* 重定向 */}
            <Route
              path={"/"}
              element={<Navigate to={`/${appList[0].name}`} replace />}
            ></Route>

            {/* 有导航栏的子应用 */}
            <Route element={<TenantLayoutWrap />}>
              {appList.map((app) => (
                <Route
                  key={app.name}
                  path={`/${app.name}/*`}
                  element={getMicroAppElement(app.name)}
                ></Route>
              ))}
            </Route>

            {/* 没有导航的子应用 */}
            {noLayoutAppList.map((app) => (
              <Route
                key={app.name}
                path={`/${app.name}/*`}
                element={getMicroAppElement(app.name, true)}
              ></Route>
            ))}

            {/*/!* isv应用 *!/*/}
            {/*{isvAppList.map((app) => (*/}
            {/*  <Route*/}
            {/*    key={app.name}*/}
            {/*    path={`/${app.name}/*`}*/}
            {/*    element={getMicroAppElement(app.name)}*/}
            {/*  ></Route>*/}
            {/*))}*/}
          </Routes>
        </BrowserRouter>
      </CustomApp>
    </ConfigProvider>
  );
}

const TenantLayoutWrap = () => {
  const location = useLocation();

  const isFullScreen = useMemo(() => {
    return appList.some((app) => {
      return (app.fullScreenRouteList ?? []).some((route) => {
        return isRouteMatching(`/${app.name}` + route, location.pathname);
      });
    });
  }, [location]);

  // 微前端主应用环境下，路由修改后触发popstate事件，否则子应用无法更新
  useEffect(() => {
    window.dispatchEvent(new PopStateEvent("popstate", { state: null }));
  }, [location]);

  return isFullScreen ? (
    <AuthorizedPage>
      <Outlet />
    </AuthorizedPage>
  ) : (
    <AuthorizedPage>
      <TenantLayout>
        <Outlet />
      </TenantLayout>
    </AuthorizedPage>
  );
};

function isRouteMatching(pattern: string, route: string) {
  const patternParts = pattern.split("/").map((part) => {
    if (part.startsWith(":")) {
      return "\\w+"; // 匹配任意单词字符
    } else {
      return part;
    }
  });

  const regex = new RegExp("^" + patternParts.join("/") + "$");

  return regex.test(route);
}

export default App;
